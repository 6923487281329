.form-group {
    margin-bottom: 10px;
}

.form-label {
    display: inline-block;
    font-size: 1.5rem;
    margin-bottom: 5px;
    cursor: pointer;
}

.form-input {
    display: block;
    width: 100%;
    padding: 15px;
    border-radius: 10px;
    outline: none;
    border: none;
    font-size: 14px;
    margin-bottom: 5px;
    font-family: Roboto, Tahoma, Geneva, Verdana, sans-serif;

    &:focus {
        border: 1px solid var(--primary);
    }
}

.message {
    font-size: 12px;
}
