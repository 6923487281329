.modal {
    font-size: 1.4rem;
}

.modal > .close {
    display: block;
    position: absolute;
    padding: 2px 5px;
    right: 10px;
    top: 10px;
    font-size: 2rem;
    margin-top: 5px;
}

.modal > .header {
    border-bottom: 1px solid gray;
    font-size: 1.8rem;
    text-align: center;
    padding-bottom: 10px;
    margin-bottom: 10px;
    color: var(--primary);
}
