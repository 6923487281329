.form {
    display: flex;
    width: 400px;
}

.input {
    display: block;
    width: 100%;
    height: 46px;
    padding: 15px;
    border-radius: 10px;
    outline: none;
    border: none;
    font-size: 14px;
    margin-bottom: 5px;
    margin-right: 5px;
    font-family: Roboto, Tahoma, Geneva, Verdana, sans-serif;

    &:focus {
        border: 1px solid var(--primary);
    }
}
