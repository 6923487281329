.container {
    width: 100vw;
    height: 100vh;
    background-image: url(../../assets/images/background.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo {
    width: 100%;
    text-align: center;
    padding-top: 15px;
    background-color: var(--primary);
}

.logo img {
    width: 100%;
    max-width: 400px;
}

.page-title {
    width: 100%;
    padding-bottom: 10px;
    color: var(--white);
    font-size: 2.2rem;
    background-color: var(--primary);
    text-align: center;
}

.box {
    width: 90%;
    padding: 15px;
    border-radius: 10px;
    margin-top: 10px;
    background-color: var(--background-color);
}

.box-scroll {
    overflow-y: scroll;
    margin-bottom: 10px;
}

.info {
    text-align: center;
}

.input-group {
    display: flex;
    justify-content: space-between;
    gap: 15px;
}

.input-group > div {
    width: 100%;
}

.input-search {
    width: 100%;
    height: 46px;
    padding: 15px;
    border-radius: 10px;
    outline: none;
    border: none;
    font-size: 14px;
    margin-bottom: 5px;
    font-family: Roboto, Tahoma, Geneva, Verdana, sans-serif;

    &:focus {
        border: 1px solid var(--primary);
    }
}

.select {
    margin-bottom: 10px;
}

.footer-sum {
    margin-top: 10px;
    font-size: 1.4rem;
}

.primary {
    margin-top: 10px;
    color: var(--primary);
}

.note {
    font-size: 1.2rem;
    font-style: italic;
}
