.container {
    width: 100vw;
    height: 100vh;
    background-image: url(../../assets/images/background.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo img {
    width: 100%;
    max-width: 400px;
    margin: 100px 0px 15px;
}

.content-heading {
    text-align: center;
    font-weight: 600;
}

.title {
    color: var(--destroy);
}

.sub-title {
    color: var(--primary);
    margin-bottom: 20px;
}

.box {
    width: 100%;
    max-width: 500px;
    padding: 15px;
    background-color: var(--background-color);
    border-radius: 20px;
}
