.sidebar {
    width: 100%;
    max-width: 300px;
    border-right: 1px solid rgba(170, 170, 170, 0.5);
    height: calc(100vh - var(--height-header) - var(--height-footer));
}

.user-box {
    display: block;
    margin: 10px 20px;
    text-align: center;

    img {
        display: block;
        width: 100%;
        max-width: 80px;
        margin: 10px auto;
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-radius: 50%;
    }

    a {
        color: black;
        text-decoration: none;
        font-weight: 600;
        font-size: 16px;
        margin: 10px 0px;
    }
}

.menu-title {
    list-style-type: none;
    background-color: var(--primary);
    color: #fff;
    padding: 12px;
    font-weight: 600;
}

.menu-list {
    list-style-type: none;
    cursor: pointer;
    transition: background-color 0.1s;

    &:hover {
        background-color: rgba(0, 132, 134, 0.5);
    }

    a {
        text-decoration: none;
        color: #000;
        display: block;
        padding: 8px 25px;
    }
}

.active > a > span {
    color: var(--primary);
    font-weight: 600;
}
